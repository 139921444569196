.maintenance-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: var(--secondaryBackground);

  div.box {
    width: 60%;
    max-width: 600px;
  }
  h4 {
    font-size: 1.4rem;
    margin-bottom: 30px;
  }
  a.home {
    background-color: white;
    padding: 15px 30px;
    border-radius: 10px;
    color: #1a1f36;
    font-size: 1rem;
    font-weight: 500;
    transition: all ease 0.3s;
    &:hover {
      transform: scale(2);
    }
  }
}
