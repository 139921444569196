.DocsSidebar {
  height: 100%;
  width: 20%;
  max-width: 430px;
  min-width: 280px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--secondaryBackground);

  @media only screen and (max-width: 600px) {
    width: 0;
    max-width: 0px;
    min-width: 0px;
  }

  .TopBar {
    position: fixed;
    z-index: 999;
    right: 0;
    width: calc(100% - 20%);
    min-width: calc(100% - 430px);
    max-width: calc(100% - 280px);
    background-color: var(--background);
    border-bottom: thin solid rgba(gray, 0.5);
    padding: 0 25px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 15px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    input[type="checkbox"] {
      position: relative;
      width: 50px;
      height: 25px;
      outline: none;
      -webkit-appearance: none;
      background-color: var(--darkModeToggleBg);
      transition: 0.5s;
      border-radius: 20px;
      cursor: pointer;
    }
    input:checked[type="checkbox"] {
      transition: 0.5s;
    }
    input[type="checkbox"]::before {
      content: "";
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 25px;
      height: 25px;
      top: 0;
      left: 0;
      background-color: var(--darkModeToggleFg);
      border-radius: 50px;
      transform: scale(0.85);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      transition: 0.5s;
      font-size: 18px;
    }
    input:checked[type="checkbox"]::before {
      left: 25px;
      content: "";
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .results {
      overflow: auto;
      height: 0;
      opacity: 0;
      margin-bottom: 0;
      .result {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        height: 60px;
        &.active,
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      &.shown {
        height: 220px;
        opacity: 1;
        margin-bottom: 20px;
      }
    }
  }

  .LogoAndColorMode {
    padding: 25px;
    padding-bottom: 10px;
    width: 100%;
  }
  .navigationItems {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding-right: 20px;
    .item {
      padding: 3px 0;
      padding-left: 25px;
      display: block;
      opacity: 0.7;
      transition: all ease 0.3s;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      &.item-head {
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        a {
          font-size: 15px;
        }
      }
      &.with-children {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      &.active {
        opacity: 1;
        background-color: rgba(gray, 0.2);
      }
      &.is-child {
        text-transform: none;
        font-weight: 400;
        &.active {
          color: var(--tintColor);
        }
      }
    }
  }
  .versionAndEcoSystem {
    width: 100%;
    padding: 20px;
    .copyright {
      width: 100%;
      text-align: left;
      font-size: 12px;
      color: gray;
    }
  }
}
