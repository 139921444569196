.SelectField {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  color: var(--font);
  transition: all ease 0.3s;
  position: relative;
  .active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray3);
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 14px;
    transition: all ease-in-out 0.3s;
    transition-delay: 0.3s;
    &.shown {
    }
  }
  .selector {
    position: absolute;
    background-color: var(--gray3); 
    opacity: 0;
    padding-top: 10px;
    padding-right: 15px;
    bottom: -400px;
    width: 320px;
    z-index: 99;
    border-radius: 20px;

    transition: all ease-in-out 0.5s;
    height: 0;
    opacity: 0;

    .option {
      // border-bottom: 0.5px solid gray;
      // border-right: 0;
      padding-bottom: 4px;
      color: var(--font);
      opacity: 0.5;
      padding: 10px;
      padding-left: 15px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;

      &.current {
        background-color: rgba(0,0,0,0.2);
        opacity: 1;
      }
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
    .header {
      font-size: 16px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
    }
    &.shown {
      height: 200px;
      opacity: 1;
      bottom: 50px;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
