h1,
h2,
h3,
h4,
h5,
p,
span,
a,
strong {
  color: var(--font);
  transition: color ease 0.2s;
  font-size: 15px;
  line-height: 28px;
}
