// @import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap");

:root {
  --background: #1d1e25;
  --secondaryBackground: #110f15;
  --thirdBackground: #35343c;
  --font: white;
  --fontFlipped: white;
  --darkModeToggleBg: #ffffff;
  --darkModeToggleFg: #110f15;

  --modalHeaderBG: #110f15;
  --modalChildBg: #1e1c23;
  --tintColor: #12a667;

  --altDark: #110f15;
  --contrast1: #676767;

  --gray3: #35343b;
}

[data-theme="light"] {
  --background: white;
  --secondaryBackground: #f8f8f8;
  --thirdBackground: #ededef;
  --font: #1d1e25;
  --fontFlipped: white;
  --darkModeToggleBg: #dadada;
  --darkModeToggleFg: white;

  --modalHeaderBG: #cecece;
  --modalChildBg: #e0dfe0;
  --tintColor: #1d1e25;

  --altDark: #cecece;
  --contrast1: #1d1e25;

  --gray3: #ededef;
}

body {
  background: var(--background);
  padding: 0 0 0 0;
  margin: 0;
  color: var(--font);
  font-size: 16px;
  line-height: 28px;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  padding: 0 0 0 0;
  font-family: "Avenir", sans-serif;
  color: var(--font);
}
div {
  color: var(--font);
}
* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

pre.__json-pretty__ {
  font-size: 0.8rem;
  font-weight: 100;
  background: transparent !important;
  padding: 10px;
}

.flexy-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.flexy-no-resize {
  flex-grow: 0;
  flex-shrink: 0;
}

@import "./global/reset";
@import "./global/type";

@import "./components/loading";
@import "./components/select-field";
@import "./components/parameter-object";
@import "./components/authentication-form";
@import "./components/parameter_view_modal";
@import "./components/version_warning";
@import "./pages/dashboard";
@import "./pages/maintenance";

div#toast-message {
  position: fixed;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  bottom: 0;
  transition: all ease-in-out 0.4s;
  &.shown {
    bottom: 20px;
    transform: translateX(-50%);
  }
  .inner-bit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px 18px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    p {
      color: gray;
    }
    svg {
      color: #1e6b1e;
      font-size: 24px;
      margin-left: 10px;
    }
  }
}

a#PoweredByDocbloc {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // transition: all ease-in-out 0.3s;
  // height: 35px !important;
  svg {
    // width: 35px !important;
    // height: 35px !important;
    transition: all ease-in-out 0.4s;

    flex-shrink: 0;
    // svg {
    // }
    #toColor {
      fill: white;
      opacity: 0.7;
    }
  }
  .message {
    margin-right: 10px;
    width: 20px;
    opacity: 0;
    height: 30px;
    transition: all ease-in-out 0.4s;
    overflow: hidden;
  }
  &:hover {
    .message {
      width: 100%;
      opacity: 1;
    }
    svg {
      #toColor {
        opacity: 1;
      }
    }
  }

  &.light {
    svg {
      #toColor {
        fill: black;
      }
    }
  }
}

.h-35 {
  height: 35%;
}

.mh6 {
  max-height: 30rem;
}

.db-main {
  color: var(--font);
}
.db-gray {
  color: var(--contrast1);
}

.bg-db-gray {
  background-color: var(--contrast1);
}

.bg-db-gray2 {
  background-color: #35343b;
}

.bg-db-gray3 {
  background-color: var(--gray3);
}

.bg-db-p {
  background-color: var(--background);
}
.bg-db-2rd {
  background-color: var(--secondaryBackground);
}
.bg-db-3rd {
  background-color: var(--thirdBackground);
}

.b--db-gray {
  border-color: #676767;
}

.pa2-5 {
  padding: 0.8rem;
}

.f4-5 {
  font-size: 1.1rem;
}

.w-93 {
  width: 93%;
}

.br5 {
  border-radius: 1.5rem;
}

.h2-5 {
  height: 3rem;
}

button {
  border: 0;
}

.bg-par-child {
  background-color: var(--modalChildBg);
}

.mx-h-500 {
  max-height: 500px;
  overflow: auto;
}

.alt-dark-bg {
  background-color: var(--altDark);
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #9c9c9c;
  border-radius: 10px;
}

::-webkit-scrollbar-button:end:increment {
  width: 2.6rem;
  display: block;
  background: transparent;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}

.w-16-6-l {
  @media screen and (min-width: 60em) {
    width: 16.6666667%;
  }
}

.acp-6-col {
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 33.333%;
  }
  @media (min-width: 1200px) {
    width: 16.66667%;
  }
}

.acp-5-col {
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 33.333%;
  }
  @media (min-width: 1200px) {
    width: 20%;
  }
}

.acp-4-col {
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 33.333%;
  }
  @media (min-width: 1200px) {
    width: 25%;
  }
}

.acp-3-col {
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 33.333%;
  }
}

.acp-2-col {
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
}

.acp-1-col {
  width: 100%;
}
