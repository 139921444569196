.main {
  display: flex;
  height: 100vh;
  width: 100vw;
  transition: background-color ease 0.2s;
  @import "../components/sidebar";
  background-color: var(--secondaryBackground);

  overflow: hidden;
  .MainContent {
    padding: 20px;
    background-color: var(--background);
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: calc(100% - 16%);
    min-width: calc(100% - 430px);
    max-width: calc(100% - 280px);
    min-height: 100vh;

    @media only screen and (max-width: 600px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding: 10px;
      padding-right: 15px;
      padding-top: 100px;
    }
    .pageSection {
      min-height: calc(100vh - 40px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 600px) {
        display: block;
      }

      .pageSectionContent {
        position: relative;
        width: 55%;
        flex-shrink: 0;

        &.full {
          width: 100%;
        }

        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }

      .copylinktosection {
        .copyable {
          display: none;
          position: absolute;
          left: -20px;
          top: 3px;
          opacity: 0.35;
          // background-color: green;
          padding-right: 12px;
          &:hover {
            opacity: 1;
            cursor: pointer;
            display: block;
          }
        }
        &:hover {
          .copyable {
            display: block;
          }
        }
      }

      h1 {
        font-size: 20px;
        margin-bottom: 20px;
        margin-left: 2rem;
      }
      h2 {
        font-size: 19px;
        margin-left: 5%;
      }

      .endpointEndpoint {
        margin-left: 2rem;
        background-color: #464646;
        width: fit-content;
        border-radius: 5px;
        min-width: 45%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        transition: all ease 0.3s;

        @media only screen and (max-width: 600px) {
          width: 96%;
        }
        .detis {
          display: flex;
          align-items: center;
          height: 3rem;
          overflow: hidden;
          border-radius: 5px;

          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        span.method {
          min-width: 75px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-weight: 600;
          font-size: 0.9rem;
          line-height: 0;
        }
        .copy {
          color: gray;
          font-size: 25px;
          margin-right: 5px;
          transition: all ease 0.3s;

          &:hover {
            cursor: pointer;
            color: white;
          }
        }
        span.endpoint {
          padding: 10px;
          color: white;
          min-width: 50%;
          max-width: 400px;
          margin-right: 10px;
          overflow: auto;
          white-space: nowrap;
          text-overflow: clip;
        }
      }
      .pageSectionContentInner {
        width: 96%;
        font-size: 14px;
        line-height: 22px;
        padding: 0 2rem;
        a {
          font-weight: 800;
          transition: all ease-in-out 0.3s;
          &:hover {
            color: var(--tintColor);
          }
        }
        h1,
        h2,
        h3 {
          margin-left: 0;
          margin-bottom: 0;
          margin-top: 0.6rem;
        }
        ol,
        ul {
          padding-left: 16px;
        }
        ul {
          list-style-type: square;
        }

        ol {
          list-style-type: decimal;
        }
        u {
          text-decoration: underline;
        }

        pre {
          font-size: 13px;
          background-color: var(--secondaryBackground);
          // display: block;
          border-radius: 10px;
          padding: 10px;
        }
      }

      .parameters-holder {
        width: 96%;
        font-size: 16px;
        line-height: 28px;
        padding: 0 2rem;
        h3 {
          margin-bottom: 12px;
        }
      }

      .endpoint-request {
        padding: 15px 10px;
        overflow: auto;
        max-height: 450px;
        &.full {
          max-height: 100%;
          padding-bottom: 100px;
        }
      }
    }
    .endpointPageContentComponent {
      width: 45%;
      overflow: hidden;
      border-radius: 10px;
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .right-item {
        background-color: #3c3e4b;
        margin-bottom: 20px;
        border-radius: 10px;
        overflow: auto;
        position: relative;
        max-width: 600px;
        .seperator {
          padding: 5px 10px;
          display: flex;
          justify-content: space-between;
          position: sticky;
          align-items: center;
          left: 0;
          h3 {
            color: white;
            font-size: 14px;
          }
          svg {
            color: white;
            font-size: 22px;
            &:hover {
              color: darkslategray;
              cursor: pointer;
            }
          }
        }
      }
    }

    .modal-overlay {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      border-bottom-left-radius: 15px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 99999;
      @media only screen and (max-width: 600px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

// .logo {
//   max-height: 50px;
//   height: 100%;
//   width: 300px;
//   max-width: 200px;
//   display: block;
// }
