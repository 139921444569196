.version-warning {
  background-color: var(--gray3);
  padding: 12px;
  border-radius: 15px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-self: start;
  svg {
    width: 25px;
    height: 25px;
    color: orange;
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px;
  }
  .textarea {
    font-size: 10px;
    h3{
        line-height: 20px;
    }
    p {
        font-size: 13.5px;
        line-height: 16px;
        opacity: 0.5;
    }
  }
}
