.parameterObjectComponent {
  padding-top: 20px;
  .parameterObjectComponentItem {
    border: thin solid rgba(148, 148, 148, 0.5);
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 7px;
    .flexy-stuff {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name-and-type {
        flex-grow: 0;
        flex-shrink: 0;
        p {
          margin-top: 4px;
          opacity: 0.5;
        }
      }
      .description-and-validation {
      }
    }

    .descriptionBoi {
      font-size: 18px;
      opacity: 0.7;
      margin-bottom: 6px;
    }
    .parameterObjectComponentItemChildren {
      transition: max-height 0.35s ease-out;
      height: auto;
      max-height: 900px;
      overflow: hidden;
      .parameterObjectComponentItem {
        margin-top: 20px;
        padding: 10px;
      }
      &.hidden {
        max-height: 0;
        transition: max-height 0.25s ease-in;
      }
    }
  }
}
