.authenticationForm {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: var(--secondaryBackground);
  form {
    background-color: var(--background);
    .password {
      position: relative;
      transition: all ease-in-out 0.3s;
      padding: 0.8rem;
      padding-left: 1.85rem;
      max-width: 400px;
      min-width: 300px;
      input {
        width: 100%;
        border: 0;
        outline: none;
        line-height: 20px;
        font-size: 16px;
        font-family: inherit;
        background: none;
        color: white;
        margin-top: 0.9rem;
        &:valid,
        &:focus {
          & + label {
            transform: translate(0, -4px) scale(0.72);
            & + .unlock {
              opacity: 1;
            }
          }
        }
      }
      label {
        position: absolute;
        z-index: 1;
        left: 2rem;
        top: 49%;
        line-height: 24px;
        font-size: 16px;
        margin: -12px 0 0 0;
        cursor: text;
        transform-origin: top left;
        transition: all 0.3s ease;
      }
      button {
        position: absolute;
        background-color: transparent;
        border: 0;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        height: 20px;
        svg {
          .ico {
            fill: #6e6e6e;
            stroke: #6e6e6e;
            transition: all ease-in-out 0.3s;
          }
        }
        &.valid,
        &:hover {
          svg {
            .ico {
              fill: white;
              stroke: white;
            }
          }
        }
      }
      &.error {
        border: 1px solid red;
      }
    }
  }
  .error-message {
    color: red;
    margin-top: 12px;
    opacity: 0;
    height: 0;
    transition: all ease-in-out 0.2s;
    padding-left: 2.2rem;
    max-width: 400px;
    min-width: 300px;
    &.shown {
      opacity: 1;
      height: 20px;
    }
  }
}
